@use "../../styles/partials/global" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.hero {
    @include flex();
    flex-direction: column;
    height: 100vh;
    overflow: hidden;

    &__bg {
        position: relative;
        height: 100%;
        width: 100%;
    }

    &__blob-left {
        position: absolute;
        top: -5%;
        left: -12%;
        z-index: 1;

    }

    &__blob-right {
        position: absolute;
        bottom: 30%;
        right: -30%;
        z-index: 2;

        @include tablet {
            bottom: 40%;
            right: -20%;
        }
    }

    &__line {
        position: absolute;
        bottom: -5%;
        left: -70%;
        height: 140%;
        z-index: 3;

        @include tablet {
            left: -35%;
            height: 165%;
        }

        @include desktop {
            left: -15%;
            height: 180%;
        }

        & path {
            stroke-width: 400px;
        }
    }

    &__text {
        text-align: center;
        position: absolute;
        top: 42%;
        z-index: 4;
    }
}
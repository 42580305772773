/* -------------------------------------------------------------------------- */
/*                                  PARTIALS                                  */
/* -------------------------------------------------------------------------- */

@use 'mixins' as *;
@use 'variables' as *;

/* -------------------------------------------------------------------------- */
/*                                 TYPOGRAPHY                                 */
/* -------------------------------------------------------------------------- */

@font-face {
    font-family: "Petrona";
    src: url(../../assets/fonts/Petrona-Regular.woff) format(woff);
    font-weight: 400;
}

@font-face {
    font-family: "Exo";
    src: url(../../assets/fonts/Exo-SemiBold.woff) format(woff);
    font-weight: 600;
}

@font-face {
    font-family: "Exo";
    src: url(../../assets/fonts/Exo-Bold.woff) format(woff);
    font-weight: 700;
}

@font-face {
    font-family: "Exo";
    src: url(../../assets/fonts/Exo-Black.woff) format(woff);
    font-weight: 800;
}

h1 {
    @include title-m();
    margin: $padding-m 0;

    @include tablet {
        @include title-d;
    }
}

h2 {
    @include subheader-lrg-m();
    margin: $padding-m 0;

    @include tablet {
        @include subheader-lrg-d;
    }
}

h3 {
    @include subheader-med-m();
    margin: $padding-d 0;

    @include tablet {
        @include subheader-med-d();
    }
}
h4 {
    @include subheader-sm();
    margin: $padding-m 0 calc($padding-m / 2) 0 ;
}

p {
    @include copy();
    margin: 0;
}

.copy-lrg {
    @include copy-lrg();
}

/* -------------------------------------------------------------------------- */
/*                                BASE STYLING                                */
/* -------------------------------------------------------------------------- */

* {
    box-sizing: border-box;
    scroll-behavior: smooth;
}

body {
    font-family: "Exo";
    margin: 0;
}

img {
    max-width: 100%;
}

ul {
    padding: 0;
    margin: 0;
}

li {
    list-style: none;
}

a {
    display: inline-block;
    text-decoration: none;
    color: black;
}

.anchor-link {
    margin: $padding-m 0;

    @include desktop {
        margin: 0 $padding-d;
        transition: $transition;

        &:hover {
            color: $s-dark;
        }
    }

    &--mobile {
        margin: 0;
        padding: $padding-m 0;
        width: 100%;
    }
}

.btn {
    @include flex();
    background-color: $p-light;
    transition: $transition;
    padding: calc($padding-m / 1.5);
    width: 100%;
    max-width: 12.5rem;
    border-radius: 0.625rem;

    &:hover {
        background-color: $s-dark;
    }
}
@use "../../styles/partials/global" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.hamburger {
    @include desktop {
        display: none;
    }

    &__icon {
        cursor: pointer;
    }

    &__line {
        height: 3px;
        width: 40px;
        background-color: $black;
        border-radius: 2px;
        margin: 8px 0;
    }

    &__nav {
        overflow: hidden;
        position: absolute;
        top: 99%;
        left: 0;
        z-index: 1;
        width: 100%;
        text-align: center;
        background-color: $p-light;
        max-height: 0;
        transition: max-height $transition ease-out;

        &.open {
            max-height: 270px;
        }
    }

    &__item {
        transition: $transition;

        &:hover {
            background: $s-dark;
        }
    }
}
@use "../../styles/partials/global" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.header {
    @include flex($justify: space-between);
    padding: $padding-m;
    background: $p-light;
    position: relative;
    z-index: 99;

    @include tablet {
        padding: $padding-d;
    }
}
 
.nav {
    display: none;

    @include desktop {
        display: block
    }

    &__list {
        @include flex();
        flex-direction: row;
    }
    
    &__item {
        padding: 0 $padding-m;

        @include tablet {
            padding: 0 $padding-d;
        }
    }
}
@use './variables' as *;

/* -------------------------------------------------------------------------- */
/*                                MEDIA QUERIES                               */
/* -------------------------------------------------------------------------- */

@mixin tablet {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 1280px) {
        @content;
    }
}

/* -------------------------------------------------------------------------- */
/*                                   STYLING                                  */
/* -------------------------------------------------------------------------- */

@mixin flex($justify: center, $align: center){
    display: flex;
    justify-content: $justify;
    align-items: $align;
  }

@mixin bg-img($size: cover, $position: center){
  background-size: $size;
  background-position: $position;
}

@mixin desktop-container ($container-max-width: 1200px) {
    margin: 0 auto;
    width: 100%;
    max-width: $container-max-width;
}

/* -------------------------------------------------------------------------- */
/*                                 TYPOGRAPHY                                 */
/* -------------------------------------------------------------------------- */

/* --------------------------------- GLOBAL --------------------------------- */

@mixin subheader-sm() {
    font-size: 1.25rem;
    line-height: 1.875rem;
    font-weight: 700;
}

@mixin copy-lrg() {
    font-family: "Petrona";
    font-size: 1.3rem;
    line-height: 1.875rem;
    font-weight: 400;
}

@mixin copy() {
    font-family: "Petrona";
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 400;
}

/* -------------------------------- MOBILE ------------------------------- */

@mixin title-m() {
    font-size: 3rem;
    line-height: 3.625rem;
    font-weight: 800;
}

@mixin subheader-lrg-m() {
    font-size: 1.75rem;
    line-height: 2.375rem;
    font-weight: 700;
}

@mixin subheader-med-m() {
    font-size: 1.5rem;
    line-height: 2.125rem;
    font-weight: 700;
}



/* --------------------------------- DESKTOP -------------------------------- */

@mixin title-d() {
    font-size: 3.5rem;
    line-height: 4.125rem;
    font-weight: 800;
}

@mixin subheader-lrg-d() {
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 700;
}

@mixin subheader-med-d() {
    font-size: 1.75rem;
    line-height: 2.375rem;
    font-weight: 700;
}

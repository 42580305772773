/* -------------------------------------------------------------------------- */
/*                                   COLORS                                   */
/* -------------------------------------------------------------------------- */

$p-dark: #39606A;
$p-light: #7CAEB9;

$s-dark: #EDDA73;
$s-light: #FAF6DB;

$off-black: #313131;
$off-white: #f5f5f5;

$black: #000;
$white: #fff;

/* -------------------------------------------------------------------------- */
/*                                   PADDING                                  */
/* -------------------------------------------------------------------------- */

$padding-m: 1rem;

$padding-d: 1.5rem;

$transition: 0.25s;